.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    text-align: center;
}

.table-container {
    width: 800px;
    justify-content: center;
    align-items: center;
}

.property-table {
    margin: 20px auto 10px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
}

.property-table th,
.property-table td {
    padding: 4px;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.property-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.property-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.property-table tbody tr:hover {
    background-color: #eaeaea;
}

.reassess-price {
    text-align: left;
}

.buttons {
    margin: 20px 10px 10px;
    border-collapse: collapse;
}

.system-selection-button-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.system-selection-button {
    padding: 10px;
}
