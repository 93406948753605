.MainWindow {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.MainWindow form {
    display: flex;
    align-items: center;
}

.input-box {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    width: 670px;
}

.submit-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #45a049;
}

.result {
    margin-top: 20px;
    /* font-size: 18px; */
    width: 100%;
    align-items: center;
}

.table {
    margin: 20px auto 10px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
}

.error-message {
    width: 750px;
    margin-top: 20px;
    text-align: left;
    background: #eeeeee;
    padding: 20px;
}

.confirm-button-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.confirm-button {
    padding: 10px;
}
