.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.container form {
    display: flex;
    align-items: center;
}

.input-box {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    width: 670px;
}

.button {
    padding: 10px 20px;
}

.confirm-button-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.confirm-button {
    padding: 10px;
}
