.table-container {
    width: 800px;
    justify-content: center;
    align-items: center;
}

.table {
    margin: 20px auto 10px;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
}

.table th,
.table td {
    padding: 4px;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}
